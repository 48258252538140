export enum RetenoFieldId {
  PDF_LINK = 257617,
  ORGANIC = 249969,
}

export enum RetenoGroup {
  POPUP_ORGANIC_EMAIL = 'popup_organic_email',
  CONFUSING_WORDS_ORGANIC_EMAIL = 'confusing_words_organic_email',
}

export enum RetenoChannelType {
  EMAIL = 'email',
}
