import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
  type FC,
  type MouseEvent,
  type SyntheticEvent,
  type TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Logo from '@promova/ui/icons/promova_logo_bold.svg'
import sortByOrder from '@promova/utils/sortByOrder'

import { Event } from '@constants_folder/analyticsEvents'
import { SHOW_FREE_FOR_UKRAINE_BUTTON } from '@constants_folder/remote_config'
import { routesWithFreeForUkButton } from '@constants_folder/routes'

import { type FooterTypes } from '@_types/landings'
import { type NavigationLink } from '@_types/navigation'
import { type CtaWithRunningTextType } from '@_types/strapi/singleTypes'

import { useCtaAppBannerOpenLogic } from '@hooks/CTA/useCtaAppBannerOpenLogic'
import { useCtaTutoringBannerOpenLogic } from '@hooks/CTA/useCtaTutoringBannerOpenLogic'
import { usePopupAllPagesOpenLogic } from '@hooks/CTA/usePopupAllPagesOpenLogic'
import useBreakpoints from '@hooks/useBreakpoints'
import useLandingInfo from '@hooks/useLandingInfo'
import useWebViewMode from '@hooks/useWebViewMode'

import { sendAnalytics } from '@utils/analytics'
import { useCtaWithTickerQuery } from '@utils/customHooks/dataFetching/CTA/useCtaWithTickerQuery'
import getFooterDataByLocale from '@utils/getFooterData'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import DownloadAppButton from '@templates/LandingNavigation/DownloadAppButton/DownloadAppButton'
import NavigationActions from '@templates/LandingNavigation/NavigationActions/NavigationActions'
import NavigationList from '@templates/LandingNavigation/NavigationList/NavigationList'
import SideNavigationWrapper from '@templates/LandingNavigation/SideNavigation/SideNavigationWrapper'
import { mainLinks } from '@templates/LandingNavigation/links'
import {
  type ConfigurableHeaderParams,
  type NavigationType,
} from '@templates/LandingNavigation/types'

import { AuthContext } from '@modules/Auth/AuthProvider'
import CtaAppBanner from '@modules/CTA/CtaAppBanner/CtaAppBanner'
import CtaBannerMobileOnlyWrapper from '@modules/CTA/CtaBannerMobileOnly/CtaBannerMobileOnlyWrapper'
import CtaBlockAllPagesWrapper from '@modules/CTA/CtaPopupAllPages/CtaNavigationBlock/CtaBlockAllPagesWrapper'
import CtaPopupAllPages from '@modules/CTA/CtaPopupAllPages/CtaPopupAllPages'
import { CtaQueueProvider } from '@modules/CTA/CtaQueueProvider/CtaQueueContext'
import CtaTimerWithButtonAllPagesWrapper from '@modules/CTA/CtaTimerWithButtonAllPages/CtaTimerWithButtonAllPagesWrapper'
import CtaTutoringBanner from '@modules/CTA/CtaTutoringBanner/CtaTutoringBanner'
import { CtaTutoringBannerMobileOnlyWrap } from '@modules/CTA/CtaTutoringBannerMobile/CtaTutoringBannerMobileOnlyWrap'
import CtaWithRunningText from '@modules/CTA/CtaWithTicker/CtaWithRunningText'
import { CtasWrapper } from '@modules/CTA/CtasWrapper/CtasWrapper'
import {
  MountedCtaContextProvider,
  useMountedCtaContext,
} from '@modules/CTA/MountedCtaContext'
import Footer from '@modules/Footer/Footer'
import DownloadAppModal from '@modules/Modal/DownloadAppModal/DownloadAppModal'

import DropdownMenu from '@elements/DropdownMenu/DropdownMenu'
import FreeForUkraineFixedBtn from '@elements/FreeUkraineButton/FreeForUkraineFixedBtn'
import FreeUkraineButton from '@elements/FreeUkraineButton/FreeUkraineButton'
import HeaderLogo from '@elements/HeaderLogo/HeaderLogo'

import styles from './landing_navigation.module.scss'

type Props = {
  type?: NavigationType
  availableLocales?: string[]
  configurableHeaderParams?: ConfigurableHeaderParams
}

const LandingNavigation: FC<Props> = ({
  type = 'default',
  children,
  availableLocales,
  configurableHeaderParams,
}) => {
  const [isScroll, setIsScroll] = useState(false)
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(false)

  const { pathname, locale } = useRouter()
  const { LANDING_PATH, LANDING_NAME } = useLandingInfo()
  const { user } = useContext(AuthContext)
  const { isWebViewMode } = useWebViewMode()
  const { xs: isMobile, sm } = useBreakpoints()

  const isFreeForUkBtnVisible = routesWithFreeForUkButton.includes(pathname)

  const shouldShowFreeForUkraineBtn = useFeatureIsOn(
    SHOW_FREE_FOR_UKRAINE_BUTTON
  )

  const isNonDistractedType = type === 'non-distracted'

  useEffect(() => {
    if (!isMobile) setIsSideNavOpen(false)
  }, [isMobile])

  useEffect(() => {
    const updateBannerClassesOnScroll = () => {
      if (!isScroll && window?.scrollY > 0) setIsScroll(true)
      if (window.scrollY === 0) setIsScroll(false)
    }

    window?.addEventListener('scroll', updateBannerClassesOnScroll)

    return () =>
      window?.removeEventListener('scroll', updateBannerClassesOnScroll)
  }, [isScroll])

  const shouldRenderCtaAppBanner = useCtaAppBannerOpenLogic()
  const shouldRenderCtaTutoringBanner = useCtaTutoringBannerOpenLogic()

  const {
    data: dataCtaWithTicker,
    isLoading: isLoadingCtaWithTicker,
    isError: isErrorCtaWithTicker,
  } = useCtaWithTickerQuery({
    locale,
  })

  const {
    data: dataCtaPopupAllPages,
    shouldRenderPopUp: shouldRenderCtaPopUpAllPages,
    shouldRenderCta: shouldRenderCtaAllPages,
    handleClose: handleClosePopupAllPages,
  } = usePopupAllPagesOpenLogic()

  const shouldRenderTickerCta: boolean = useMemo(() => {
    const isDataLoaded =
      !isLoadingCtaWithTicker && !isErrorCtaWithTicker && !!dataCtaWithTicker

    const isPopupStateValid = !dataCtaWithTicker?.isDisabled

    const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
      currentUrl: LANDING_PATH,
      includedUrls: dataCtaWithTicker?.urlsToInclude || [],
      excludedUrls: dataCtaWithTicker?.urlsToExclude || [],
    })

    return isDataLoaded && isPopupStateValid && isCTAVisibleBasedOnUrl
  }, [
    dataCtaWithTicker,
    isLoadingCtaWithTicker,
    isErrorCtaWithTicker,
    LANDING_PATH,
  ])

  const mountedCtaContext = useMountedCtaContext()

  useEffect(() => {
    if (mountedCtaContext) {
      mountedCtaContext.setCtaWithRunningText(shouldRenderTickerCta)
    }
  }, [mountedCtaContext, shouldRenderTickerCta])

  const handleOpenModal = useCallback(() => {
    setIsDownloadAppModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsDownloadAppModalOpen(false)
  }, [])

  const handleToggleSideNav = (e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.stopPropagation()

    setIsSideNavOpen(!isSideNavOpen)
  }

  const handleConfigurableHeaderClickTab = (
    e?: SyntheticEvent<HTMLElement>
  ) => {
    const title = e?.currentTarget?.dataset?.title
    const route = e?.currentTarget?.dataset?.route

    sendAnalytics({
      eventName: Event.GEN_CLICKED_HEADER_BUTTON,
      data: {
        title,
        where: 'header',
        page_path: route,
      },
    })
  }

  const handleNavLinkClick = (
    e: MouseEvent<HTMLAnchorElement> | TouchEvent<HTMLAnchorElement>
  ) => {
    const linkText = e.currentTarget?.dataset?.title
    const linkRoute = e.currentTarget?.dataset?.route
    const linkRouteLastPart = linkRoute?.slice(linkRoute.lastIndexOf('/') + 1)

    sendAnalytics({
      eventName: Event.GEN_CLICKED_CTA,
      data: {
        place: 'header',
        text: linkText,
        type: linkRouteLastPart,
        button_number: 0,
        page_path: LANDING_NAME,
      },
    })
  }

  const validatedType: NavigationType =
    type === 'configurable' && !configurableHeaderParams ? 'default' : type

  const isConfigurable = validatedType === 'configurable'

  const footerType: NavigationType = isConfigurable
    ? configurableHeaderParams?.footer || 'default'
    : validatedType

  const configurableLink = configurableHeaderParams?.downloadAppButton?.value
  const downloadAppLink =
    isConfigurable && !!configurableLink ? configurableLink : undefined

  const renderMinimalLogo =
    validatedType === 'minimal' ||
    (isConfigurable && configurableHeaderParams?.logo === 'not_clickable')

  const renderClickableLogo =
    validatedType === 'clickable-logo-only' ||
    (isConfigurable && configurableHeaderParams?.logo === 'clickable')

  const sortedConfiguredLinks = sortByOrder(
    configurableHeaderParams?.links || []
  )

  const configuredLinks: NavigationLink[] =
    sortedConfiguredLinks.map((link) => ({
      route: link?.value,
      label: link?.key,
      id: link?.value,
      order: link?.order,
    })) || []

  const headerClassNames = classNames(styles.header, {
    [styles.header_static]:
      isConfigurable && configurableHeaderParams?.position === 'static',
  })

  const navigationsClassNames = classNames(styles.navigation, {
    [styles.navigation_scroll]: isScroll,
    [styles.fade_out]:
      (isScroll && shouldRenderCtaAppBanner) ||
      (isScroll && shouldRenderCtaTutoringBanner),
    [styles.hidden]: isWebViewMode,
  })

  const footerData = getFooterDataByLocale(locale)

  return (
    <>
      <header className={headerClassNames}>
        {shouldRenderTickerCta && (
          <CtaWithRunningText
            data={dataCtaWithTicker as CtaWithRunningTextType}
          />
        )}
        {sm && (
          <CtaBlockAllPagesWrapper
            data={dataCtaPopupAllPages}
            isOpen={shouldRenderCtaAllPages}
          />
        )}

        <CtaBannerMobileOnlyWrapper />

        <CtaTimerWithButtonAllPagesWrapper location="top" isScroll={isScroll} />

        <nav className={navigationsClassNames}>
          {renderMinimalLogo && !isNonDistractedType && (
            <Logo className={styles.logo} />
          )}
          {(renderClickableLogo || isNonDistractedType) && (
            <Link href="/">
              <a className={styles.nav_link}>
                <HeaderLogo />
              </a>
            </Link>
          )}

          {isConfigurable && !isNonDistractedType && (
            <>
              <div className={styles.navigation_list}>
                <NavigationList
                  links={configuredLinks}
                  onClick={handleConfigurableHeaderClickTab}
                />
              </div>

              <div className={styles.desktop_container}>
                {!configurableHeaderParams?.downloadAppButton?.isDisabled &&
                  configurableHeaderParams?.downloadAppButton?.key && (
                    <DownloadAppButton
                      content={configurableHeaderParams?.downloadAppButton?.key}
                      onClick={handleOpenModal}
                    />
                  )}
                {user ? (
                  <DropdownMenu />
                ) : (
                  <NavigationActions
                    onCloseSidebar={handleToggleSideNav}
                    availableLocales={availableLocales}
                    showFreeUkraineButton={false}
                    showLanguageSwitch={
                      configurableHeaderParams?.languageSwitch
                    }
                  />
                )}
              </div>
              <SideNavigationWrapper
                onClick={handleToggleSideNav}
                active={isSideNavOpen}
                links={configuredLinks}
                onClose={handleToggleSideNav}
                availableLocales={availableLocales}
                showFreeUkraineButton={false}
                showLanguageSwitch={configurableHeaderParams?.languageSwitch}
              />
            </>
          )}
          {validatedType === 'default' && !isNonDistractedType && (
            <>
              <Link href="/">
                <a className={styles.nav_link}>
                  <HeaderLogo />
                </a>
              </Link>

              <div className={styles.navigation_list}>
                <NavigationList
                  links={mainLinks}
                  onClick={handleNavLinkClick}
                />
              </div>

              <SideNavigationWrapper
                onClick={handleToggleSideNav}
                onNavLinkClick={handleNavLinkClick}
                active={isSideNavOpen}
                onClose={handleToggleSideNav}
                availableLocales={availableLocales}
                links={mainLinks}
              />

              <div className={styles.desktop_container}>
                {!shouldShowFreeForUkraineBtn && (
                  <DownloadAppButton onClick={handleOpenModal} />
                )}
                {user ? (
                  <>
                    <div className={styles.free_ukraine_box}>
                      <FreeUkraineButton />
                    </div>
                    <DropdownMenu />
                  </>
                ) : (
                  <NavigationActions
                    onCloseSidebar={handleToggleSideNav}
                    availableLocales={availableLocales}
                  />
                )}
              </div>
            </>
          )}
        </nav>

        {!sm && (
          <CtaBlockAllPagesWrapper
            data={dataCtaPopupAllPages}
            isOpen={shouldRenderCtaAllPages}
          />
        )}

        <CtaTimerWithButtonAllPagesWrapper
          location="bottom"
          isScroll={isScroll}
        />

        {shouldRenderCtaAppBanner && <CtaAppBanner />}

        {shouldRenderCtaTutoringBanner && <CtaTutoringBanner />}

        <CtaPopupAllPages
          data={dataCtaPopupAllPages}
          isOpen={shouldRenderCtaPopUpAllPages}
          handleClose={handleClosePopupAllPages}
        />
      </header>

      {isDownloadAppModalOpen && (
        <DownloadAppModal
          isOpen={isDownloadAppModalOpen}
          onClose={handleCloseModal}
          qrCodeLink={downloadAppLink}
        />
      )}

      <div>{children}</div>
      <CtaTutoringBannerMobileOnlyWrap />
      <CtasWrapper />
      {isFreeForUkBtnVisible && <FreeForUkraineFixedBtn />}
      {!isWebViewMode && !isNonDistractedType && (
        <Footer
          type={footerType}
          footerData={footerData as unknown as FooterTypes}
        />
      )}
    </>
  )
}

const LandingNavigationWithProviders: FC<Props> = ({ children, ...rest }) => (
  <CtaQueueProvider>
    <MountedCtaContextProvider>
      <LandingNavigation {...rest}>{children}</LandingNavigation>
    </MountedCtaContextProvider>
  </CtaQueueProvider>
)
export default LandingNavigationWithProviders
