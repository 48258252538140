import { ApprovalStatus } from '@_types/strapi/common'

import { colorsLibrary, fontsLibrary } from '@utils/strapiCommons/constants'
import {
  ColorDataTypes,
  ColorNameTypes,
  FontNameTypes,
  FontParamsTypes,
} from '@utils/strapiCommons/types'

import { OneStringColorTypes } from '@features/ConfigurableCheckout/types/types'

export const getColor = (colorData?: ColorDataTypes) => {
  const colorName = colorData?.name
  if (!colorName) return undefined
  const colorOpacity = colorData?.opacity

  const color = colorsLibrary[colorName][colorOpacity || 100]

  if (!color && colorsLibrary[colorName]) return colorsLibrary[colorName][100]

  return color
}

const splitColor = (color: OneStringColorTypes): ColorDataTypes => {
  const [name, opacity] = color?.color?.split('/') as [ColorNameTypes, string]

  return {
    name,
    opacity: Number(opacity),
  }
}

export const getOneStringColor = (color?: OneStringColorTypes) => {
  if (!color) return undefined

  const colorData = splitColor(color)

  return getColor(colorData)
}

export const getFont = (fontName: FontNameTypes) => {
  if (!fontName) return undefined

  const font = fontsLibrary[fontName]

  return font || undefined
}

export const createCSSFontVariables = ({
  fontSize,
  fontSizeMobile,
}: FontParamsTypes) => ({
  [`--size` as string]: fontSize,
  [`--size-mobile` as string]: fontSizeMobile,
})

const COMMENTS_PER_PAGE = 10
const COMMENTS_FIRST_PAGE = 1

export const createCommentsOptions = ({
  page = COMMENTS_FIRST_PAGE,
  pageSize = COMMENTS_PER_PAGE,
}: {
  page?: number
  pageSize?: number
}) => ({
  sort: 'createdAt:DESC',
  'pagination[page]': page,
  'pagination[pageSize]': pageSize,
  'pagination[withCount]': true,
  filters: {
    $and: [
      {
        $or: [
          { approval_status: { $eq: ApprovalStatus.Approved } },
          // null for backward compatibility - for all the comments which
          // were created before we enabled the 'Approval flow' feature
          { approval_status: { $null: true } },
        ],
      },
      {
        blocked: { $eq: false },
      },
    ],
  },
})

export const createOptionsForFetchingComments = ({
  page = COMMENTS_FIRST_PAGE,
  pageSize = COMMENTS_PER_PAGE,
}: {
  page?: number
  pageSize?: number
}): string => JSON.stringify(createCommentsOptions({ page, pageSize }))
