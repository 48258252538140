import classNames from 'classnames'
import toggleBodyClassName from 'promova-student/utils/toggleBodyClassName'
import { CSSProperties, FC, MouseEvent, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useMediaQuery } from 'react-responsive'

import useWindowHeight from '@promova/utils/customHooks/useWindowHeight'

import Icon from '../Icon/Icon'
import styles from './modal.module.scss'

/*
Modal - modal window
which open in center and have inner scroll.
Modal close when user click on empty place or close button in desktop
then call onClose function from props
 */

/*
Modal - modal window
which open in center and have inner scroll.
Modal close when user click on empty place or close button in desktop
then call onClose function from props
 */
interface Props {
  opened: boolean // if true modal opened
  withCloseButton?: boolean // if true in desktop modal has close button
  hideMobileCloseButton?: boolean // if true in mobile modal has not close button
  isFullScreen?: boolean // if true mobile modal will have a full screen height
  isFullScreenDesktop?: boolean // if true desktop modal will have a full screen height (100vh - 100px)
  onClose?: (e?: any) => void // callback which call when user close modal
  overlayClickDisabled?: boolean // if true modal will not close when user click on overlay
  modalClassName?: string
  overlayClassName?: string
  style?: CSSProperties
  closeButtonStyle?: CSSProperties
  contentWrapperClassName?: string
  isParentScrollDisabled?: boolean // if true parent scroll will be disabled
}

const stopPropagation = (e: MouseEvent<HTMLElement>) => e.stopPropagation()

const Modal: FC<Props> = ({
  opened,
  onClose,
  withCloseButton,
  hideMobileCloseButton,
  isFullScreen,
  isFullScreenDesktop,
  overlayClickDisabled,
  children,
  modalClassName = '',
  overlayClassName = '',
  style,
  closeButtonStyle,
  contentWrapperClassName,
  isParentScrollDisabled,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const { windowHeight } = useWindowHeight()

  useEffect(() => {
    if (isParentScrollDisabled) toggleBodyClassName('stop_scrolling')

    return () => {
      if (isParentScrollDisabled) toggleBodyClassName('stop_scrolling')
    }
  }, [isParentScrollDisabled])

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  if (!opened) return null

  const handleCloseClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setTimeout(() => {
      if (typeof onClose === 'function') onClose()
    }, 10) // Short delay to ensure outside clicks are not immediately triggered
  }

  const containerClassName = classNames(styles.container, overlayClassName, {
    [styles.container_opened]: opened,
  })

  const modalClassNames = classNames(styles.modal, {
    [styles.modal_full_screen]: isFullScreen && isMobile,
    [styles.modal_with_close_button]: withCloseButton && !hideMobileCloseButton,
    [modalClassName]: !!modalClassName,
  })

  const closeBtnClassNames = classNames(styles.close_button, {
    [styles.close_button_mobile_hidden]: hideMobileCloseButton,
  })

  const contentWrapperClassNames = classNames(
    styles.content_wrapper,
    contentWrapperClassName
  )

  let maxHeight = `calc(${windowHeight}px - 100px)`
  let height = isFullScreenDesktop ? maxHeight : 'auto'

  if (isMobile) {
    maxHeight = `${windowHeight}px`
    height = isFullScreen ? maxHeight : 'auto'
  }

  return createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={containerClassName}
      style={{ height: windowHeight }}
      onClick={overlayClickDisabled ? stopPropagation : handleCloseClick}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        style={style}
        className={modalClassNames}
        ref={modalRef}
        onClick={stopPropagation}
      >
        {withCloseButton && (
          <button
            type="button"
            onClick={handleCloseClick}
            className={closeBtnClassNames}
            aria-label="close"
            style={closeButtonStyle}
          >
            <Icon icon="close" />
          </button>
        )}
        <div
          className={contentWrapperClassNames}
          style={{
            maxHeight,
            height,
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root') || document.body
  )
}

export default Modal
